<template>
    <div class="row">

        <LeftSection :list_check_box="list_check_box" :links="data.data_links" />


         <!-- Right Section -->
        <div class="col-12 col-lg-9 wrapper-box-fiche">         
            <template v-for="(item, key) in list_check_box">               
                <div class="box-white-fiche" :key="key" v-if="show_block.includes(key) && item.enable" :id="key">
                      <!-- Section Title Start -->
                    <div class="box-icon-openbar">
                        <div class="box-click-open" @click="item.show = !item.show">
                            <h3 :class="item.orange_text ? 'text-orange' : ''" style="margin-bottom: 5px;">{{ $t(item.title) }}</h3>
                            <em :class="item.show ? 'fa fa-pull-right fa-angle-up arrow-up' : 'fa fa-pull-right fa-angle-down arrow-down'"></em>
                        </div>
                        <div class="clearfix"></div>
                    </div>
                    <!-- Section Title End -->
                    
                    
                    <!-- Section Detail Start -->
                    <!--General information start-->
                    <div class="content-onglets table" v-if="key == 'detail_general_information' && item.show && data.nmpa">
                        <table class="comparateur2 border-top-gray text-left" v-if="key == 'detail_general_information' && item.show" >
                            <tbody>
                                <tr v-if="data.submission_date">
                                    <td class="bold bg-white" width="20%"><strong style="color:#000;">{{$t('Submission date')}}</strong></td>
                                    <td class="bg-white">{{DDMMMYY(data.submission_date)}}</td>
                                </tr>
                                <tr v-if="data.guidance_id">
                                    <td class="bold bg-white" width="20%"><strong style="color:#000;">{{$t('Decison code')}}</strong></td>
                                    <td class="bg-white">{{data.guidance_id}}</td>
                                </tr>
                            </tbody>
                        </table>  
                    </div>
                    <!--General information end-->


                    <!--detail_indication start-->
                    <Indication  v-else-if="key == 'detail_indication' && item.show"/>
                    <!--detail_indication end-->

                    <!-- treatment_line start -->
                    <TreatmentLine v-else-if="key == 'treatment_line' && item.show"/>
                    <!-- treatment_line end -->

                    <!-- detail_comment start -->
                    <div class="content-onglets collapse show" v-if="key == 'detail_comment' && item.show" >                        
                        <span v-html="data.nmpa.comment" ></span>
                    </div>  
                    <!-- detail_comment end -->

                    <KeyDocument v-else-if="key=='detail_key_documents' && item.show" :data="data.key_documents" :list_document_exist="data.list_document_exist"/>

                    <!-- detail_clinical_trials start-->
                    <EssaisClinique v-else-if="key=='detail_clinical_trials' && item.show" :datas="data.essais_clinique"/>
                    <!-- detail_clinical_trials end-->


                </div>
            </template>

            <!-- Link Section Start-->       
            <LinkAgency v-for="(data_agency,Agency) in data.data_links" :data="data_agency" :link_agency="Agency" :key="Agency" />
            <!-- Link Section End -->
        </div>
        <!-- Right Section End -->
    </div>
</template>

<script>
import LeftSection from '../LeftSection.vue';
import TreatmentLine from '../treatment_line.vue'
import LinkAgency from '../LinkAgency.vue';      
import KeyDocument from '../key_document.vue';
import EssaisClinique from '../../elements/essais_clinique_detail.vue';
import Indication from '../Indication.vue'
import {key_documents} from '../../../utils'
export default{
    name: 'nmpa',
    components : {
        LeftSection,
        LinkAgency,
        KeyDocument,
        EssaisClinique,
        TreatmentLine,
        Indication
    },
    data(){
        return {
            list_check_box : {
                // show -> show detail, 
                // enable -> show [check box] on left box, 
                // orange_text -> if true: color of title text is orange
                detail_general_information : { 'title' : 'general_information', 'show' : true, 'enable' : false, 'orange_text':false }, 
                detail_indication : { 'title' : 'Indication', 'show' : true, 'enable' : false, 'orange_text':false},               
                treatment_line : {'title' : 'population', 'show' : true, 'enable' : false, 'orange_text':false },
                detail_comment : {'title' : 'comment_detail', 'show' : true, 'enable' : false, 'orange_text':false },                
                detail_clinical_trials : {'title' : 'Clinical trials', 'show' : true, 'enable' : false, 'orange_text':true },             
                detail_key_documents : {'title' : 'Key documents', 'show' : true, 'enable' : false, 'orange_text':false },             
            } 
        }
    },
    created(){
        // condition to show check box in left section [enable]
        if(this.data && this.data.nmpa){   

           if(this.data['submission_date'] || this.data['guidance_id']){
                this.list_check_box['detail_general_information'].enable = true
            }
            if(this.data['indication_en']){ 
                this.list_check_box['detail_indication'].enable = true
            }
            if(this.data.treatment_line || this.data.specificity || this.data.administration)  { 
                this.list_check_box['treatment_line'].enable = true
            }
            if(this.data.nmpa['comment']){ 
                this.list_check_box['detail_comment'].enable = true 
            }
            if(key_documents(this.data.key_documents) && key_documents(this.data.key_documents).length > 0){ 
                this.list_check_box['detail_key_documents'].enable = true
            }
            if(this.data.essais_clinique && this.data.essais_clinique.length > 0) { 
                this.list_check_box['detail_clinical_trials'].enable = true
            }

        }
    },
    methods : {      
        key_documents
    },
    computed : {
        data(){
            return this.$store.getters['detail/data']
        },
        show_block : {
            set(val) { this.$store.dispatch('detail/set_show_block', val)},
            get() {return this.$store.getters['detail/show_block']}
        },
        view_enabled() {  
            return this.$store.getters['detail/view_enabled']        
        }
    },
}

</script>

<style>

</style>